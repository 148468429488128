import TranslationData from "./Data";
import Locale from './Locale';
import { UnknownTargetLocaleError } from "./errors";
import * as Parsers from './parsers'

export interface TranslationParserConstructor {
  canParse: (file: File) => boolean;
  new (): TranslationFileParser;
}

export interface TranslationFileParser {
  parse: (file: File) => Promise<TranslationData>|TranslationData;
  readonly fileType: string;
  readonly accept: string;
}


export default abstract class TranslationParser implements TranslationFileParser {
  abstract readonly fileType: string;
  abstract readonly accept: string;

  abstract parse(file: File): Promise<TranslationData>|TranslationData;

  protected getLocale(locale: string): Locale {
    try {
      return new Locale(locale);
    } catch (e) {
      throw new UnknownTargetLocaleError(locale);
    }
  }

  static get accepts() {
    return Object.entries(Parsers)
      .map(([_, parser]) => new parser().accept)
      .join(',');
  }
}
