import { configure, defineRule } from 'vee-validate';
import {
  required,
  email,
  min,
  confirmed,
  alpha_spaces,
  alpha_dash,
  url,
  min_value,
  max_value,
  integer,
  numeric
} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import { splitBreaklineString } from '@/utils/string';
import Node from "@/models/Node";
import Option from "@/models/Option";
import OptionSet from "@/models/OptionSet";
import sanitizeHtml from 'sanitize-html'
import messages from "@/lang/en/validations";

defineRule('email', email);
defineRule('required', required);
defineRule('min', min);
defineRule('confirmed', confirmed);
defineRule('alpha_spaces', alpha_spaces);
defineRule('alpha_dash', alpha_dash);
defineRule('url', url);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('integer', integer);
defineRule('numeric', numeric);

export const answersLineLength = (value: string) => {
  const longAnswer = splitBreaklineString(value).some(answer => answer.length > Option.MAX_TEXT_LENGTH)

  return !longAnswer;
}

export const questionTextLength = (value: string) => {
  const longAnswer = splitBreaklineString(value).some(answer => answer.length > Node.MAX_TEXT_LENGTH)

  return !longAnswer;
}

export const isStringSanitized = (value: string) => {
  const sanitizedString = sanitizeHtml(value)

  return value.length === sanitizedString.length
}

defineRule('answers_line_length', answersLineLength);

defineRule('answers_lines_quantity', (value: string) => {
  return splitBreaklineString(value).length <= OptionSet.MAX_NUM_OPTIONS
})

defineRule('sanitize', isStringSanitized);


configure({
  generateMessage: localize('en', {
    messages: {
      required: '{field}',
      url: 'Enter a valid URL',
      color: 'Enter a valid HEX code. i.e. #FFF or #FFFFFF',
      emails: 'This field must be a valid list of emails',
      domains: '{field}',
      min_value: `Minimum value is 0:{min_value}`,
      max_value: `Maximum value is 0:{max_value}`,
      answers_line_length: `Maximum of ${Option.MAX_TEXT_LENGTH} characters per line is reached`,
      answers_lines_quantity: `Maximum of ${OptionSet.MAX_NUM_OPTIONS} answer options per question is reached`,
      sanitize: messages.validations.invalidStringWithXSS,
    }
  })
})
