import { useShellStore } from "@maru/shell-app";

export default class Locale {
  private locale: Intl.Locale;

  constructor(locale: string) {
    try {
      this.locale = new Intl.Locale(locale.replace('_', '-'));
    } catch (e) {
      throw new Error(`Unknown locale ${locale}`, e);
    }
  }

  static get default(): Locale {
    return new Locale('en');
  }

  static get user(): Locale {
    const shellStore = useShellStore();

    return shellStore.userLocale
      ? new Locale(shellStore.userLocale)
      : Locale.default;
  }

  get ISO15897Code(): string {
    return this.region ? `${this.language}_${this.region}` : this.language;
  }

  get IETFLanguageTag(): string {
    return this.locale.baseName
  }

  get language(): string {
    return this.locale.language;
  }

  get region(): string {
    return this.locale.region;
  }

  toString(): string {
    return this.IETFLanguageTag;
  }

  equals(locale: Locale) : boolean {
    return this.IETFLanguageTag === locale.IETFLanguageTag;
  }

  displayName(locale: string|Locale = Locale.user) {
    return new Intl.DisplayNames(
      [ locale.toString() ],
      { type: 'language', languageDisplay: 'standard' }
    ).of(this.locale.baseName)
  }
}
