import {BaseNodeParams, NodeOrdinalitiesTypes, NodeTranslations, NodeTypeId} from "@/models/Node";
import {QuestionType2dOrdinalities} from "@/models/nodeTypes/QuestionType2d";
import {OptionSetParams} from "@/models/nodeTypes/QuestionType1d";
import QuestionTypeSingleSelectGrid from "@/models/nodeTypes/QuestionTypeSingleSelectGrid";
import Survey from "@/models/Survey";
import {cloneDeep} from "@/utils/lodash";

export enum RatingScaleType {
  Stars = 'stars',
  Hearts = 'hearts',
  Smiles = 'smiles',
}

interface RatingScaleTypeProperties {
  type: RatingScaleType;
  icons: string | string[];
  min: number;
  max: number;
}

type RatingScaleTypeMap = {
  [Value in RatingScaleType]: RatingScaleTypeProperties;
};

/**
 * Columns (set2) are set according to the number of scale points required with label set to a number.
 * Columns are hidden in the UI but used in routing and will allow setting/translating a label in future
 *  i.e. Very happy, Happy, etc. vs. just setting as 1, 2 to start.
 */
export default class QuestionTypeSingleSelectGridScale<
  TQuestionTypeSingleSelectGridScaleOrdinalities extends QuestionTypeSingleSelectGridScaleOrdinalities = QuestionTypeSingleSelectGridScaleOrdinalities
> extends QuestionTypeSingleSelectGrid<TQuestionTypeSingleSelectGridScaleOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-heart-box-outline';
  static readonly NODE_TITLE: string = 'question.type.singleSelectGridScale';
  static readonly NODE_EDIT_COMPONENT: string = 'QuestionTypeSingleSelectGridScaleForm';
  static readonly NODE_SET2_HIDDEN: boolean = true;
  static readonly NODE_SET2_NUMERIC: boolean = true;
  static readonly DEFAULT_SCALE_TYPE: RatingScaleType = RatingScaleType.Stars;
  static readonly DEFAULT_SCALE_POINTS: number = 5;
  static readonly NODE_SUPPORT_SET1_OPTIONS_ASSETS: boolean = false;

  static readonly NODE_SCALE_TYPES: RatingScaleTypeMap = {
    [RatingScaleType.Stars]: { type: RatingScaleType.Stars, icons: 'star', min: 1, max: 10 },
    [RatingScaleType.Hearts]: { type: RatingScaleType.Hearts, icons: 'heart', min: 1, max: 10 },
    [RatingScaleType.Smiles]: { type: RatingScaleType.Smiles, icons: ['frown-open', 'frown', 'meh', 'smile', 'grin'], min: 5, max: 5 },
    // add any new icon sets here
  }

  static readonly DEFAULT_SCALE_ICONS: string = QuestionTypeSingleSelectGridScale.NODE_SCALE_TYPES[QuestionTypeSingleSelectGridScale.DEFAULT_SCALE_TYPE].icons as string;
  static readonly DEFAULT_ORDINALITIES: QuestionTypeSingleSelectGridScaleOrdinalities = {
    rating: { icons: QuestionTypeSingleSelectGridScale.DEFAULT_SCALE_ICONS }
  };
  static readonly ORDINALITY_TYPES: NodeOrdinalitiesTypes = {
    rating: { icons: ['string', 'string[]'] },
  }

  constructor(
      baseNodeParams: BaseNodeParams,
      ordinalities = cloneDeep(QuestionTypeSingleSelectGridScale.DEFAULT_ORDINALITIES) as TQuestionTypeSingleSelectGridScaleOrdinalities,
      i18n: NodeTranslations = {},
      optionSetParams: OptionSetParams = {},
  ) {
    optionSetParams.addSet2PlaceholderOption = false; // don't want a placeholder added for scale points

    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.SingleSelectGridScale;
    this.validateNumericColumns(baseNodeParams.survey);
  }

  getMinNumColumnsAllowed(survey: Survey = null): number {
    return this.getRatingScaleTypeProperties().min;
  }

  getMaxNumColumnsAllowed(survey: Survey = null): number {
    return this.getRatingScaleTypeProperties().max;
  }

  getDefaultNumColumns(): number {
    return QuestionTypeSingleSelectGridScale.DEFAULT_SCALE_POINTS;
  }

  setIcons(scale: RatingScaleType) {
    this.ordinalities.rating.icons = QuestionTypeSingleSelectGridScale.NODE_SCALE_TYPES[scale].icons;
  }

  getRatingScaleType(): RatingScaleType {
    return Object.keys(QuestionTypeSingleSelectGridScale.NODE_SCALE_TYPES).find((key) => {
      const typeIcons = QuestionTypeSingleSelectGridScale.NODE_SCALE_TYPES[key as RatingScaleType].icons;
      return JSON.stringify(typeIcons) === JSON.stringify(this.ordinalities.rating.icons);
    }) as RatingScaleType;
  }

  getRatingScaleTypeProperties(): RatingScaleTypeProperties {
    return QuestionTypeSingleSelectGridScale.NODE_SCALE_TYPES[this.getRatingScaleType()];
  }

}

export interface QuestionTypeSingleSelectGridScaleOrdinalities extends QuestionType2dOrdinalities {
  rating: {
    icons: string | string[];
  }
}
