import * as csvToJson from 'convert-csv-to-json';
import TranslationData from "../Data";
import TranslationParser, {TranslationParserConstructor} from "@/models/translation/Parser";

// Don't use method chaining here as it causes TypeError with built assets
csvToJson.utf8Encoding();
csvToJson.indexHeader(5);
csvToJson.supportQuotedField(true);
csvToJson.fieldDelimiter(',');

const CsvParser: TranslationParserConstructor = class CsvParser extends TranslationParser {
  readonly fileType = 'CSV';
  readonly accept = 'text/csv'

  static canParse(file: File): boolean {
    return file.type === 'text/csv';
  }

  async parse(file: File): Promise<TranslationData> {
    const csv = await file.text(),
      targetLocale = this.getLocale(csv.split('\n')[3].split(',')[1].trim() as string),
      data = csvToJson.csvStringToJson(csv);

    return new TranslationData(data, targetLocale)
  }
}

export default CsvParser;
