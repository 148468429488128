import {stripHTML, uuid} from "@/utils/string";
import ValidationException from "@/exceptions/ValidationException";
import i18n from "@/plugins/i18n";
import Asset from "./Asset";
import Node from "@/models/Node";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";
import Survey from "@/models/Survey";
import QuestionType1d from "@/models/nodeTypes/QuestionType1d";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import OptionSet from "@/models/OptionSet";

export default class Option {
  static readonly DEFAULT_PIN = false;
  static readonly DEFAULT_EXCLUSIVE = false;
  static readonly DEFAULT_SPECIFY = false;
  static readonly DEFAULT_RULE = null;
  static readonly DEFAULT_HIDE = false;
  static readonly MAX_TEXT_LENGTH = 600; //TODO: increase after performance testing
  //DEFAULT_IMAGE = false; //TODO: assume need this to indicate if it's an image only answer, but not currently in design?

  constructor(
    public code: number,
    public ref: string = null,
    public label: string = '',
    public pin: boolean = Option.DEFAULT_PIN,
    public exclusive: boolean = Option.DEFAULT_EXCLUSIVE,
    public specify: boolean = Option.DEFAULT_SPECIFY,
    public i18n: OptionTranslations = {},
    public asset: Asset = null,
  ) {

    this.ref = this.ref ?? uuid();
    // Coerce to an object
    this.i18n = Object.assign({}, this.i18n);

    this.code = typeof this.code === "number" ? this.code : parseInt(this.code);
    if (isNaN(this.code)) {
      //TODO: add data
      throw new ValidationException(`The code for option with id ${this.ref} must be numeric.`)
    }

    this.label = this.label ?? '';
    this.pin = this.pin ?? Option.DEFAULT_PIN;
    this.exclusive = this.exclusive ?? Option.DEFAULT_EXCLUSIVE;
    this.specify = this.specify ?? Option.DEFAULT_SPECIFY;
  }

  validate(node: QuestionType1d|QuestionType2d, optionSet: OptionSet, survey: Survey, codeChangeMap: Map<number, number> = null, validateText: boolean = true, validateIncomplete = true) {
    if (validateText) {
      this.validateText(node, optionSet, survey, codeChangeMap, validateIncomplete);
    }

    const optionSetNum = optionSet.getNodeOptionSetProperty(node);
    if (optionSetNum === 'set1') {
      this.validateAnswerSupports(node, 1);
    } else if (optionSetNum === 'set2') {
      this.validateAnswerSupports(node, 2);
    }
  }

  validateText(node: QuestionType1d, optionSet: OptionSet, survey: Survey, codeChangeMap: Map<number, number> = null, validateIncomplete: boolean = true) {
    const hasText = this.label !== null && this.label !== '';
    if (hasText) {
      this.validatePipes(node, survey, codeChangeMap);
    }
    if (hasText || validateIncomplete) {
      this.validateTextContent(node, optionSet, survey);
    }
  }

  validateTextContent(node: QuestionType1d, optionSet: OptionSet, survey: Survey) {
    survey.resetSurveyErrors(this.ref, null, [SurveyErrorProperty.OptionText]);

    const setProperty = optionSet.getNodeOptionSetProperty(node);
    const isOptionMaskingChild = setProperty ? (node as QuestionType1d).isOptionMasked(this, setProperty) : false;
    if (isOptionMaskingChild) {
      // don't display text errors on masking children, since they can't update them there
      return;
    }

    if (!optionSet.isPlaceholderOption(this)) {
      // exceeds max length
      const stripped = stripHTML(this.label, true);
      if (stripped.length > Option.MAX_TEXT_LENGTH) {
        survey.addSurveyError(new SurveyError(SurveyErrorType.Length, SurveyErrorProperty.OptionText, this.ref, node.ref, {max: Option.MAX_TEXT_LENGTH}));
      }

      // no content
      if (!this.hasMedia() && !this.hasLabelText()) {
        survey.addSurveyError(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.OptionText, this.ref, node.ref));
      }
    }
  }

  validatePipes(node: Node, survey: Survey, codeChangeMap: Map<number, number> = null) {
    survey.validatePipes(this, node, codeChangeMap);
  }

  validateAnswerSupports(node: QuestionType1d|QuestionType2d, setNum: number) {
    const specifySupport = node.constructor[`NODE_SET${setNum}_SUPPORTS_SPECIFY`]
    if (!specifySupport && this.specify) {
      this.specify = false
    }

    const exclusiveSupport = node.constructor[`NODE_SET${setNum}_SUPPORTS_EXCLUSIVE`]
    if (!exclusiveSupport && this.exclusive) {
      this.exclusive = false
    }
  }

  hasLabelText(): boolean {
    return (this.label !== null && this.label.trim() !== '');
  }

  hasMedia(): boolean {
    return this.asset !== null;
  }

  getCleanTitle() {
    const { t } = i18n.global;

    return this.label?.length
      ? stripHTML(this.label)
      : t('question.noOptionLabel');
  }

  addTranslation(locale: string, translation: string|null = null) {
    this.i18n[locale] = translation;
  }
}

export type OptionTranslations = Record<string, string>;
