export default {
  translations: {
    add: {
      title: 'Add languages to translate your survey',
      description: 'Want to reach a global audience? Add the languages you need from our selection of {langs} supported options. The cost is determined by the number of words for each language - no surprises| Start by adding the languages, and we\'ll show you the breakdown.',
      btn: 'Add languages',
      noMoreAvailable: 'All supported languages have already been added to the survey.',
    },
    track: {
      title: 'Translations',
      description: 'Track your translations here! You’ll see all the languages you’ve added along with their completion status. Check the progress and make sure everything is on track for your multilingual survey.',
      btn: 'Get bulk quote',
    },
    base: {
      title: 'Base language',
      description: 'Your survey\'s base language is automatically set based on your account\'s region settings. If you need to change it, no problem! Just update your region in your profile, and the base language will adjust.',
      hint: 'To update base language, go to your profile and adjust your region settings.',
    },
    table: {
      header: {
        language: 'Language',
        code: 'Code',
        completionRate: 'Completion rate',
        visibleToRespondent: 'Visible to respondent',
        actions: 'Actions',
      },
      language: {
        base: {
          detail: '{lang} is the default language and is locked in based on your region settings. To change it, update the region in your profile.'
        }
      },
      completionRate: {
        detail: '{num} out of {total}',
      },
      visibleToRespondent: {
        yes: 'Yes',
        no: 'No',
      },
      error: {
        unsupportedFile: 'Unsupported file format',
        unsupportedTargetLocale: 'Unsupported target locale',
        unknown: 'Unknown error while uploading translations',
        unsupportedLanguage: 'The {language} language is not supported.',
        cannotDeleteDefaultLocale: '{language} is the default locale for this survey and cannot be deleted',
        unknownTranslations: {
          title: 'Unknown translation strings',
          description: 'The following translations could not be found in the current survey and will not be added if you proceed.',
        },
        surveyPreview: 'Unknown error while trying to preview translation',
      },
      actions: {
        view: 'View',
        preview: 'Preview',
        upload: 'Upload translation file',
        download: 'Download translation file',
        delete: 'Delete',
        getQuote: 'Get quote',
      },
      confirmUploadDialog: {
        title: 'Upload {lang} translations',
        headers: {
          filename: 'Filename',
          type: 'Type',
          sourceLocale: 'Source locale',
          targetLocale: 'Target locale',
          translations: 'Translations',
        },
        emptyFile: 'This file does not contain any translated strings',
        wrongSurvey: 'This file does not contain translations for this survey',
        wrongLocale: 'This file does not contain translations targeting {lang}',
        actions: {
          confirm: 'Confirm',
          cancel: 'Cancel',
        }
      },
      confirmDeleteDialog: {
        title: 'Delete {locale} translations?',
        description: 'Are you sure you want to delete the {locale} translations',
        confirm: 'Confirm',
      },
      viewTranslationsDialog: {
        title: '{locale} translations',
        loading: {
          title: 'Loading translations'
        }
      },
    },
    addLanguagesDialog: {
      title: 'Add languages',
      description: 'Let\'s take your survey global! Browse through our 30+ supported languages and choose the ones you need. Add as many as you like, and we\'ll handle the rest.',
      search: {
        label: 'Search',
        empty: 'No languages match your search.',
      },
      actions: {
        add: 'Add',
        cancel: 'Cancel',
      }
    },
    getBulkQuoteDialog: {
      title: 'Bulk translation quote request',
      description: 'You\'ve selected multiple languages for translation. We\'re preparing a bulk quote once ready, you\'ll receive a detailed cost breakdown for all the selected languages.',
      actions: {
        getBulkQuote: 'Get bulk quote',
        decline: 'Decline',
      }
    },
  }
}
