export default {
  navigation: {
    workspace: {
      home: 'Home',
      example: 'Example',
      builder: 'Builder',
      translations: 'Translations',
    },
  },
}
