import {defineStore} from "pinia";
import i18n from "@/plugins/i18n";
import Survey from "@/models/Survey";
import Page from "@/models/Page";
import {ACTIVE_EDIT_ELEMENT_TYPES, ActiveEditElement, ActiveEditElementType, Ref} from "@/typings/global-types";
import Node, {NodeTypeId} from "@/models/Node";
import {createNode, nodeTypeClasses} from "@/utils/nodeTypeFactory";
import OptionSet from "@/models/OptionSet";
import QuestionType1d from "@/models/nodeTypes/QuestionType1d";
import RuleSet from "@/models/RuleSet";
import Element from "@/models/Element";
import {isDevEnv} from "@/constants/config";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";
import {cloneDeep} from "@/utils/lodash";
import {getTypedSurvey} from "@/tests/mocks/seeds/surveyStore";

const { t } = i18n.global;

export const useSurveyStore = defineStore('surveyStore', {
  state: () => ({
    survey: null as Survey,
    activeEditElementRef: null as string,
    activeEditElementType: Page as unknown as ActiveEditElementType,
    unsubscribeHandler: null,
    edited: false,
    sessionStarted: false,
    sessionSaved: false,
    sessionLocked: false,
    editingUserId: null as string,
    isNew: true,
    isPublished: false,
    entity: null as Record<string, any>,
    v2: true, // enable the features of the second version: Re-use Survey, Import Questions Library
  }),
  getters: {
    hasSurvey(): boolean {
      return this.survey?.elements && this.survey?.elements.length > 0;
    },
    getActiveEditElement(): ActiveEditElement {
      // returns active EDIT element, which includes nodes
      if (this.activeEditElementRef === null || this.activeEditElementType === null) {
        return null;
      }
      if (this.activeEditElementType === Node) {
        return this.survey.getNodeByRef(this.activeEditElementRef);
      }
      return this.survey.getElementByRef(this.activeEditElementRef);
    },
    getActiveElement(): Element {
      // returns page if active element is a node
      return this.getActiveQuestion
        ? this.survey.getPageOfNode(this.getActiveQuestion)
        : this.getActiveEditElement;
    },
    getActivePage(): Page | null {
      return this.activeEditElementType === Page ? this.getActiveEditElement : null;
    },
    getActiveRuleSet(): RuleSet | null {
      return this.activeEditElementType === RuleSet ? this.getActiveEditElement : null;
    },
    getActiveQuestion(): Node | null {
      return this.activeEditElementType === Node ? this.getActiveEditElement : null;
    },
    getPageOfActiveQuestion(): Page | null {
      return this.getActiveQuestion
        ? this.survey.getPageOfNode(this.getActiveQuestion)
        : null;
    },
    getIndexOfActiveElement(): number | null {
      // returns SURVEY element index - if active edit element is node, it'll return it's page
      return this.getActiveQuestion ? this.getPageIndexOfActiveQuestion
        : this.getActiveEditElement ? this.survey.getElementIndex(this.getActiveEditElement)
        : null;
    },
    getIndexOfActiveQuestionOnPage(): number | null {
      return this.getActiveQuestion ? this.getPageOfActiveQuestion.getNodeIndex(this.getActiveQuestion) : null;
    },
    getPageIndexOfActiveQuestion(): number | null {
      return this.getPageOfActiveQuestion
        ? this.survey.getElementIndex(this.getPageOfActiveQuestion)
        : null;
    },
    getActiveOptionSet: (state: any) => (setNum = 1) => {
      const question = state.getActiveQuestion;
      if (question instanceof QuestionType1d && (question['set' + setNum] ?? false)) {
        return state.survey.sets.find((el) => el.ref === question['set' + setNum]);
      }
      return null;
    },
    getActiveOptionSet1(): OptionSet {
      return this.getActiveOptionSet(1);
    },
    getActiveOptionSet2(): OptionSet {
      return this.getActiveOptionSet(2);
    },
    questionRoutes: (state: any) => (sliceBeforePage: boolean | null = null, pageSliceIndex: number | null = null, types: NodeTypeId[] = null, isPipe = false) => {
      // returns questions by type and page position, if provided
      const nodes = state.survey.getAllNodes(types, sliceBeforePage ? pageSliceIndex : null, !sliceBeforePage ? pageSliceIndex : null);

      return nodes.map(node=> {
        return {
          label: node.getCleanTitle(),
          code: node.getCodeLabel(isPipe),
          ref: node.ref,
          type: node.type,
          disabled: false,
        }
      });
    },
    pageRoutes: (state: any) => (sliceBeforePage: boolean | null = null, pageSliceIndex: number | null = null) => {
      const pages = state.survey.getAllPages(sliceBeforePage ? pageSliceIndex : null, !sliceBeforePage ? pageSliceIndex : null);

      return pages.map(page=> {
        const nodeLabels = page.getAllNodeLabels();

        return {
          label: `${page.getCleanTitle()}${nodeLabels ? ' (' + nodeLabels + ')' : ''}`,
          code: page.getCodeLabel(),
          goToRef: page.ref,
          disabled: false,
        }
      });
    },
    isEdited(): boolean {
      return this.edited;
    },
    isSessionStarted(): boolean {
      return this.sessionStarted;
    },
    isSessionLocked(): boolean {
      return this.sessionLocked;
    },
    isSessionSaved(): boolean {
      return this.sessionSaved;
    },
    surveyTitle(): string {
      return this?.entity?.name || t('builder.draftSurvey.defaultName')
    },
    editElementHasErrors: (state: any) => (element: ActiveEditElement = null): boolean => {
      return state.getEditElementErrors(element).length > 0
    },
    getEditElementErrors: (state: any) => (element: ActiveEditElement): SurveyError[] => {
      return state.getErrors(null, null, element.ref);
    },
    hasErrors: (state: any) => (errorProperties: SurveyErrorProperty[] = null, errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorTypes: SurveyErrorType[] = null): boolean => {
      return !!state.getErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes).length;
    },
    getErrors: (state: any) => (errorProperties: SurveyErrorProperty[] = null, errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorTypes: SurveyErrorType[] = null): SurveyError[] => {
      return state.survey.filterSurveyErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes);
    },
    getErrorMessages: (state: any) => (errorProperties: SurveyErrorProperty[] = null, errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorTypes: SurveyErrorType[] = null): string[] => {
      return state.survey.filterSurveyErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes, false, true);
    },
  },
  actions: {
    setSurvey(survey: Survey) {
      this.survey = survey;
      //for testing purposes, uncomment below to see state changes in console
      // this.subscribe();
      const firstElement = this.survey.elements[0];
      let firstNode = null;
      if (firstElement instanceof Page) {
        firstNode = this.survey.getAllPages()?.[0].nodes?.[0];
      }
      this.setActiveEditElement(firstNode ?? firstElement);
    },
    initSurvey() {
      //for testing purposes, uncomment below to set to test survey
      // this.setSurvey(getTypedSurvey())
      // this.setSurvey(getMaskedQuestionSurvey())
      this.addNewPage(NodeTypeId.SingleSelect, null, true, 1, 1)
    },
    clearSurvey() {
      this.survey = null;
    },
    subscribe() {
      this.unsubscribe();
      this.unsubscribeHandler = this.$subscribe((mutation, state) => {
        if (isDevEnv()) {
          console.info('MUTATION', mutation);
          console.info('MUTATED STATE', {...state});
          console.info('MUTATED SURVEY STATE', {...state?.survey})
        }
      });
    },
    unsubscribe() {
      if (this.unsubscribeHandler) {
        this.unsubscribeHandler();
      }
    },
    addNewPage(addNodeType: NodeTypeId = null, pageIndex: number = null, setActive: boolean = true, pageCode: number = null, nodeCode: number = null): Page {
      const page = new Page({survey: this.survey, code: pageCode});
      pageIndex = pageIndex ?? (this.getIndexOfActiveElement !== null ? this.getIndexOfActiveElement + 1 : null);

      let node = null;

      if (addNodeType) {
        const defaultOrdinalities = cloneDeep(nodeTypeClasses[addNodeType]['DEFAULT_ORDINALITIES']);
        node = createNode({type: addNodeType, survey: this.survey, code: nodeCode}, defaultOrdinalities);
        page.addNode(node);
      }

      this.survey.addElement(page, pageIndex);

      if (setActive) {
        this.setActiveEditElement(node ?? page);
      }

      this.survey.handleElementPositionChange([], setActive ? [page] : [], node && setActive ? [page.nodes[0]] : []);

      return page;
    },
    addNewNode(nodeType: NodeTypeId, nodeIndex: number = null, nodeCode = null, pageIndex: number = null, setActive: boolean = true): Node | null {
      const page = pageIndex !== null ? this.survey.getElementByIndex(pageIndex) : this.getActivePage ?? this.getPageOfActiveQuestion;

      if (page instanceof Page) {
        const defaultOrdinalities = cloneDeep(nodeTypeClasses[nodeType]['DEFAULT_ORDINALITIES']);
        const node = createNode({type: nodeType, survey: this.survey, code: nodeCode}, defaultOrdinalities);
        nodeIndex = nodeIndex ?? (this.getIndexOfActiveQuestionOnPage !== null ? this.getIndexOfActiveQuestionOnPage + 1 : null);
        page.addNode(node, nodeIndex);

        if (setActive) {
          this.setActiveEditElement(node);
        }

        this.survey.handleElementPositionChange([], [], setActive ? [node] : []);

        return node;
      }

      return null;
    },
    addNewRuleSet(index: number = null,  setActive: boolean = true): RuleSet {
      index = index ?? (this.getIndexOfActiveElement !== null ? this.getIndexOfActiveElement + 1 : null);
      const ruleSet = this.survey.addElement(new RuleSet({survey: this.survey}), index);

      if (setActive) {
        this.setActiveEditElement(ruleSet);
      }

      this.survey.handleElementPositionChange(setActive ? [ruleSet] : [], [], []);

      return ruleSet;
    },
    setActiveEditElement(element: ActiveEditElement = null) {
      this.activeEditElementRef = element?.ref ?? null;
      this.activeEditElementType = null;

      for (const type of ACTIVE_EDIT_ELEMENT_TYPES) {
        if (element instanceof type) {
          this.activeEditElementType = type;
          break;
        }
      }
    },
    setActiveEditElementToNearest(previousElement: boolean = true, oldElementIndex: number = null, oldNodeIndex = null, elementType: ActiveEditElementType = null) {
      let newElement: ActiveEditElement = null;

      oldNodeIndex = oldNodeIndex ?? (elementType === Node ? this.getIndexOfActiveElement : null);
      oldElementIndex = oldElementIndex ?? this.getIndexOfActiveElement;

      const getNextValidElement = (elements: ActiveEditElement[], oldIndex: number): ActiveEditElement | null => {
        if (previousElement && oldIndex === 0 && elements[0]) {
          return elements[0];
        }

        const searchByDirection = (direction) => {
          for (let i = oldIndex + direction; i >= 0 && i < elements.length; i += direction) {
            if (elements[i]) {
              return elements[i];
            }
          }
          return null;
        }

        const direction = previousElement ? -1 : 1;

        // reverse direction if none found
        return searchByDirection(direction) ?? searchByDirection(-direction);
      };

      if (elementType === Node) {
        // set to nearest question, if any, else page
        const page = this.survey.getElementByIndex(oldElementIndex) as Page;
        const newNode = page?.nodes.length ? getNextValidElement(page.nodes, oldNodeIndex) : null;
        newElement = newNode || page || null;
      }

      if (!newElement && this.survey.elements.length) {
        // set to nearest element
        newElement = getNextValidElement(this.survey.elements, oldElementIndex);
      }

      if (newElement instanceof Page && newElement.nodes.length) {
        // set first node of page to active
        newElement = newElement.nodes[0];
      }

      // should always at least be one page/question
      this.setActiveEditElement(newElement ?? this.survey.elements[0] ?? null);
    },
    deleteElement(element: ActiveEditElement = null) {
      let elementIndex: number = null;
      let nodeIndex: number = null;
      element = element ?? this.getActiveEditElement;

      let elementType = null;
      for (const type of ACTIVE_EDIT_ELEMENT_TYPES) {
        if (element instanceof type) {
          elementType = type;
          break;
        }
      }

      if (element instanceof Element) {
        elementIndex = this.survey.getElementIndex(element);
        this.survey.deleteElement(element);
      } else if (element instanceof Node) {
        const page = this.survey.getPageOfNode(element);
        elementIndex = this.survey.getElementIndex(page);
        nodeIndex = page.getNodeIndex(element);
        page.deleteNode(element, this.survey);
      }

      //add a new page with single select node, if none exist
      if (!this.survey.elements.length) {
        this.addNewPage(NodeTypeId.SingleSelect, null, true, 1, 1);
      }

      this.setActiveEditElementToNearest(true, elementIndex, nodeIndex, elementType);
    },
    duplicateElement(newName: string = null, element: ActiveEditElement = null) {
      element = element ?? this.getActiveEditElement;

      const pageOfNode = element instanceof Node ? this.survey.getPageOfNode(element) : null;
      const elementIndex = pageOfNode?.getNodeIndex(element as Node) ?? this.survey.getElementIndex(element as Element);

      // add dupe after element
      let newElement = pageOfNode
        ? pageOfNode.duplicateNode(element as Node, this.survey, newName, elementIndex + 1)
        : this.survey.duplicateElement(element, elementIndex + 1, newName);

      if (newElement instanceof Page && newElement.nodes.length) {
        // set first node of page to active
        newElement = newElement.nodes[0];
      }

      this.survey.handleElementPositionChange();

      this.setActiveEditElement(newElement);
    },
    markEdited() {
      this.edited = true
      this.isNew = false
    },
    resetEdited() {
      this.edited = false
    },
    saveSession() {
      this.sessionSaved = true
    },
    resetSession() {
      this.sessionSaved = false
    },
    startSession() {
      this.sessionStarted = true
    },
    finishSession() {
      this.sessionStarted = false
    },
    lockSession(editingUserId) {
      this.editingUserId = editingUserId;
      this.sessionLocked = true;
      this.finishSession();
    },
    setNew(val: boolean) {
      this.isNew = val
    },
    setPublished(val: boolean) {
      this.isPublished = val
    },
    setEntity(entity: Record<string, any>) {
      this.entity = entity;
    },
  }
})
