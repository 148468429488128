import TranslationData from "../Data";
import TranslationParser, {TranslationParserConstructor} from "@/models/translation/Parser";
import xliff from 'xliff';
import Locale from '../Locale'

type XliffJS = {
  resources: {
    survey: Record<string, XliffTranslationRecord>
  },
  sourceLanguage: string,
  targetLanguage:string,
}

type XliffTranslationRecord = {
  source: string,
  target: string,
  additionalAttributes: { name: string },
}

const XliffParser: TranslationParserConstructor = class XliffParser extends TranslationParser {
  readonly fileType = 'XLIFF';
  readonly accept = '.xlf';

  static canParse(file: File): boolean {
    return file.name.split('.').pop() === 'xlf';
  }

  async parse(file: File): Promise<TranslationData> {
    const [parsedData, targetLocale] = await this.preParse(file),
      data = [];
    Object.entries(parsedData?.resources?.survey).forEach(([ref, translation]) => {
      data.push({
        context: translation.additionalAttributes.name,
        id: ref,
        'original-text': translation.source,
        'translated-text': translation.target,
      });
    })

    return new TranslationData(data, targetLocale)
  }

  private async preParse(file: File): Promise<[XliffJS, Locale]> {
    const content = await file.text(),
      domParser = (new DOMParser()).parseFromString(content, "text/xml"),
      version = domParser.documentElement.getAttribute('version');

    switch (version) {
      case "1.2":
        return [
          await xliff.xliff12ToJs(content),
          this.getLocale(domParser.documentElement.firstElementChild.getAttribute('target-language'))
        ];
      default:
        return [
          await xliff.xliff2js(content),
          this.getLocale(domParser.documentElement.getAttribute('trgLang'))
        ];
    }
  }
}

export default XliffParser;
