export default class CustomError extends Error {
  constructor(cause: Error = null) {
    super('', { cause });
    this.name = this.constructor.name;
  }

  get translateOptions(): Record<string, string> {
    return {};
  }
}
