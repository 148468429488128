import {BaseNodeParams, NodeTranslations, NodeTypeId} from "@/models/Node";
import QuestionType2d, {QuestionType2dOrdinalities} from "@/models/nodeTypes/QuestionType2d";
import {OptionSetParams} from "@/models/nodeTypes/QuestionType1d";
import {cloneDeep} from "@/utils/lodash";

export default class QuestionTypeSingleSelectGrid<
  TQuestionTypeSingleSelectGridOrdinalities extends QuestionTypeSingleSelectGridOrdinalities = QuestionTypeSingleSelectGridOrdinalities
> extends QuestionType2d<TQuestionTypeSingleSelectGridOrdinalities> {

  static readonly NODE_ICON: string = 'mdi-grid';
  static readonly NODE_TITLE: string = 'question.type.singleSelectGrid';
  static readonly NODE_SUPPORT_SET1_OPTIONS_ASSETS: boolean = false;

  constructor(
      baseNodeParams: BaseNodeParams,
      ordinalities = cloneDeep(QuestionType2d.DEFAULT_ORDINALITIES) as TQuestionTypeSingleSelectGridOrdinalities,
      i18n: NodeTranslations = {},
      optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n, optionSetParams);

    this.type = NodeTypeId.SingleSelectGrid;
  }
}

export interface QuestionTypeSingleSelectGridOrdinalities extends QuestionType2dOrdinalities {}
