import navigation from "@/lang/en/navigation";
import selectBuilt from "@/lang/en/select-built";
import question from "@/lang/en/question";
import builder from "@/lang/en/builder";
import generics from "@/lang/en/en-generics";
import enAnswer from "@/lang/en/en-answer";
import mediaUploader from "@/lang/en/mediaUploader";
import enFiles from "@/lang/en/en-files";
import enA11y from "@/lang/en/en-a11y";
import actions from "@/lang/en/actions";
import confirmation from "@/lang/en/confirmation";
import alerts from "@/lang/en/alerts";
import suggestedAnswers from "@/lang/en/suggestedAnswers";
import surveyRail from "@/lang/en/surveyRail";
import validations from "@/lang/en/validations";
import translations from "@/lang/en/translations";

export default {
  ...navigation,
  ...selectBuilt,
  ...question,
  ...builder,
  ...generics,
  ...enAnswer,
  ...mediaUploader,
  ...enFiles,
  ...enA11y,
  ...actions,
  ...confirmation,
  ...alerts,
  ...suggestedAnswers,
  ...surveyRail,
  ...validations,
  ...translations,
}
