import Node, {
  BaseNodeParams,
  NodeOrdinalities, NodeTranslations,
  QUESTION_1D_TYPES, QUESTION_TYPES_SUPPORTED_MASKING,
} from "@/models/Node";
import Survey from "@/models/Survey";
import OptionSet from "@/models/OptionSet";
import Option from "@/models/Option";
import ValidationException from "@/exceptions/ValidationException";
import {uuid} from "@/utils/string";
import {cloneDeep} from "@/utils/lodash";
import i18n from "@/plugins/i18n";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import QuotaGroup from "@/models/QuotaGroup";
import Rule, {RuleChoiceValue} from "@/models/Rule";
import { Ref, Referable } from "@/typings/global-types";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";

export enum LayoutAnswerColumns {
  One = "one-column",
  Two = "two-columns",
  Three = "three-columns"
}

export enum MaskingType {
  Selected = 'selected',
  NotSelected = 'not-selected',
}

/**
 * Question types with one option set, such as single select and multi select types.
 */
export default abstract class QuestionType1d<
  TQuestionType1dOrdinalities extends QuestionType1dOrdinalities = QuestionType1dOrdinalities
> extends Node<TQuestionType1dOrdinalities> {

  static readonly NUM_SETS: number = 1;
  static readonly DEFAULT_OPTION_CONFIGURATION: QuestionOptionConfigurationsProps = {
    rule: Option.DEFAULT_RULE,
    hide: Option.DEFAULT_HIDE,
    parentRef: null,
  }
  static readonly DEFAULT_MASKING_TYPE: MaskingType = MaskingType.Selected;
  static readonly DEFAULT_SET1_MASKING_OPTIONS: QuestionOptionMaskingProps = {
    type: QuestionType1d.DEFAULT_MASKING_TYPE,
    parentNode: null,
    parentSet: 'set1'
  };
  static readonly DEFAULT_ORDINALITIES: QuestionType1dOrdinalities = {};
  static readonly NODE_EDIT_COMPONENT: string = 'QuestionType1dForm';
  static readonly NODE_SET1_SUPPORTS_SPECIFY: boolean = false;
  static readonly NODE_SET1_SUPPORTS_EXCLUSIVE: boolean = false;
  static readonly NODE_SUPPORT_SET1_OPTIONS_ASSETS: boolean = true;

  set1: Ref;
  optionConfigurations: QuestionOptionConfigurations = {};
  optionMasking: QuestionOptionMasking = {};
  quotaGroupRef: Ref = null;

  protected constructor(
    baseNodeParams: BaseNodeParams,
    ordinalities = cloneDeep(QuestionType1d.DEFAULT_ORDINALITIES) as TQuestionType1dOrdinalities,
    i18n: NodeTranslations = {},
    optionSetParams: OptionSetParams = {},
  ) {
    super(baseNodeParams, ordinalities, i18n);

    const {survey} = baseNodeParams;
    const {addSet1PlaceholderOption, set1, quotaGroupRef, optionConfigurations, optionMasking } = optionSetParams;

    this.set1 = set1 ?? null;
    this.quotaGroupRef = quotaGroupRef ?? null;

    this.validateOptionSet('set1',optionConfigurations ?? {}, survey, optionMasking?.set1 ?? [], false, false);
    this.validateQuestionQuotaGroup(survey);

    // add placeholder
    if (addSet1PlaceholderOption ?? true) {
      this.getSet1(survey).addPlaceholderOption(this);
    }
  }

  validateOptionSet(
    setNum: OptionSetProperty,
    optionConfigurations: QuestionOptionConfigurations,
    survey: Survey,
    optionMasking: QuestionOptionMaskingProps[],
    validateOptionText: boolean = true,
    validateIncomplete: boolean = true
  ) {
    let optionSet: OptionSet;
    const setRef = this[setNum];
    if (setRef !== null) {
      optionSet = survey.getOptionSetByRef(setRef);
      if (!optionSet) {
        //TODO: flush out helpful error data
        throw new ValidationException(`An option set with ref ${setRef} defined in "set1" of node doesn't exist.`,
          {node: {
              ref: this.ref,
              name: this.name,
              [setNum]: setRef,
            }}
        )
      } else {
        optionSet.validate(survey, this, null, validateOptionText, validateIncomplete);
      }
    } else {
      // create an option set, if it doesn't exist
      optionSet = new OptionSet();
      survey.addOptionSet(optionSet);
    }

    this.setQuestionOptionConfigurations(optionSet, optionConfigurations);

    this[setNum] = optionSet.ref;

    this.setQuestionOptionMasking(survey, optionMasking, setNum);
  }

  validateQuestionQuotaGroup(survey: Survey) {
    const quotaGroup = this.getQuestionQuotaGroup(survey);
    if (quotaGroup) {
      const options = this.getSet1Options(survey) ?? [];
      options.forEach((option, index) => {
        const quota = survey.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option);
        if (quota) {
          // ensure name matches
          survey.updateOptionQuotaInQuestionQuotaGroup(option, this, quotaGroup);
        } else {
          // add missing quota
          survey.addOptionQuotaToQuestionQuotaGroup(option, this, index, quotaGroup);
        }
      })

      // remove invalid quotas
      quotaGroup.quotas.forEach((quota, index) => {
        const rule = quota.rule as Rule;
        const ruleOptionRef = (rule.value as RuleChoiceValue)?.set1;
        if (rule.node !== this.ref || !options.some(option => option.ref === ruleOptionRef)) {
          quotaGroup.quotas.splice(index, 1)
        }
      })

      // ensure name and order matches question
      survey.updateQuestionQuotaGroupName(this, quotaGroup);
      survey.updateQuestionQuotaGroupQuotaPositions(this, quotaGroup);

    } else {
      this.quotaGroupRef = null;
    }
  }

  getQuestionQuotaGroup(survey: Survey): QuotaGroup | null {
    return this.quotaGroupRef ? survey.getQuotaByRef(this.quotaGroupRef) as QuotaGroup : null;
  }

  getSet1(survey: Survey): OptionSet | null {
    return survey.getOptionSetByRef(this.set1);
  }

  getSet1Options(survey: Survey): Option[] | null {
    return this.getSet1(survey)?.options ?? null;
  }

  getOptionByRef(optionRef: string, survey: Survey): Option | null {
    return this.getSet1(survey).getOptionByRef(optionRef);
  }

  setQuestionOptionConfigurations(optionSet: OptionSet, optionConfigurations: QuestionOptionConfigurations = {}) {
    optionSet.options.forEach(option => {
      const optionConfiguration = Object.hasOwn(optionConfigurations, option.ref)
        ? optionConfigurations[option.ref]
        : cloneDeep(QuestionType1d.DEFAULT_OPTION_CONFIGURATION);

      this.setQuestionOptionConfiguration(option.ref, optionConfiguration)
    })
  }

  setQuestionOptionConfiguration(optionRef: string, optionConfigurations: QuestionOptionConfigurationsProps = QuestionType1d.DEFAULT_OPTION_CONFIGURATION) {
    // add default for any missing option config property, just in case, don't think we need to error
    //TODO: should probs validate parent option masking ref exists just in case - delete option if doesn't?
    const cleanConfig = {};
    for (const key in QuestionType1d.DEFAULT_OPTION_CONFIGURATION) {
        cleanConfig[key] = Object.hasOwn(optionConfigurations, key)
          ? optionConfigurations[key]
          : QuestionType1d.DEFAULT_OPTION_CONFIGURATION[key];
    }
    this.optionConfigurations[optionRef] = cleanConfig as QuestionOptionConfigurationsProps;
  }

  setQuestionOptionMasking(survey: Survey, maskingOptions: QuestionOptionMaskingProps[] = [], optionSetProperty: OptionSetProperty = 'set1') {
    const errorProperty = optionSetProperty === 'set1' ? SurveyErrorProperty.OptionMaskingSet1 : SurveyErrorProperty.OptionMaskingSet2;
    survey.resetSurveyErrors(null, this.ref, [errorProperty]);

    // masking options are stored as an array to support masking from multiple questions in future,
    // but we are only supporting one question for now, so this is hardcoded to the first index throughout
    const hasMaskingOptions = Array.isArray(maskingOptions) && maskingOptions.length && Object.keys(maskingOptions?.[0] ?? {}).length && maskingOptions[0].parentNode;
    const oldMaskingOptions = this.optionMasking?.[optionSetProperty]?.[0];
    const hasParentNodeChanged = oldMaskingOptions && oldMaskingOptions.parentNode !== maskingOptions?.[0]?.parentNode;
    if (!hasMaskingOptions || hasParentNodeChanged) {
      this.deleteMaskedOptions(optionSetProperty, survey, !hasMaskingOptions);
    }

    if (hasMaskingOptions) {
      this.optionMasking[optionSetProperty] ??= [];
      this.optionMasking[optionSetProperty] = maskingOptions;

      const parentNode = survey.getNodeByRef(maskingOptions[0].parentNode) as QuestionType1d;

      if (maskingOptions[0].parentNode && !parentNode) {
        // this shouldn't happen, but check just in case
        survey.addSurveyError(new SurveyError(SurveyErrorType.NonExistant, errorProperty, this[optionSetProperty], this.ref));
        return;
      }

      const childSet =  survey.getOptionSetByRef(this[optionSetProperty]);
      const parentSet = survey.getOptionSetByRef(parentNode[maskingOptions[0].parentSet]);
      const parentSetOptions = parentSet?.options ?? [];
      const placeholderOption = parentSet?.getPlaceholderOption();

      parentSetOptions.forEach((parentOption, index) => {
        if (parentOption !== placeholderOption) {
          this.addMaskedOption(parentNode, parentOption, childSet, survey, index);
        }
      })

      childSet.addPlaceholderOption(this);
    } else {
      delete this.optionMasking?.[optionSetProperty]
    }

    // recursively update masking in children of children
    const maskedNodes = this.getMaskingChildNodesBySet(survey, optionSetProperty);
    maskedNodes.forEach(childNode => {
      const childOptionMasking = childNode.optionMasking?.set1;
      if (childOptionMasking) {
        childNode.setQuestionOptionMasking(survey, childOptionMasking, optionSetProperty);
      }

      // validate child's options length
      const optionSet = survey.getOptionSetByRef(this[optionSetProperty]);
      optionSet.validateOptionsLength(survey, childNode);
    })
  }

  updateMaskedChildOption(parentOption: Option, survey: Survey, optionDeleted = false, optionAdded = false, optionSetProperty: OptionSetProperty = 'set1', checkChildren: boolean = true) {
    const maskedNodes = this.getMaskingChildNodesBySet(survey, optionSetProperty);

    if (maskedNodes.length) {
      maskedNodes.forEach(childNode => {
        const childOptionSet = survey.getOptionSetByRef(childNode[optionSetProperty]);
        const childOptionRef = childNode.getMaskedOptionRefByParentRef(parentOption.ref);

        let childOption = childOptionRef ? childOptionSet.getOptionByRef(childOptionRef) : null;
        if (childOption && optionDeleted) {
          // delete the option
          childOptionSet.deleteOptionByIndex(survey, childOptionSet.getOptionIndexByRef(childOptionRef), childNode, optionSetProperty);
        } else if (optionAdded && !childOption) {
          // add the option
          childOption = childNode.addMaskedOption(this, parentOption, childOptionSet, survey);
        } else if (childOption) {
          // option was updated
          if (parentOption.specify) {
            //TODO: will need to sort this when deal with translations as don't want this to trigger a survey change when language is changed
            // also need to update to include the question # specify is coming from
            // i.e. childOption.label = `{Q${i18n.global.t('question.questionSpecifyPipe', {questionCode: parentNode.code})}}`;
            childOption.label = i18n.global.t('question.questionSpecifyPipe');
          } else {
            childOption.label = parentOption.label;
          }
          // specifies are piped, this should always be false
          childOption.specify = false;
          survey.updateOptionQuotaInQuestionQuotaGroup(childOption, childNode);
        }

        // recursively check for children with masking
        if (childOption && checkChildren) {
          childNode.updateMaskedChildOption(childOption, survey, optionDeleted, optionAdded, optionSetProperty);
        }
      })
    }
  }

  updateMaskedChildOptionPositions(survey, optionSetProperty: OptionSetProperty = 'set1') {
    const maskedNodes = this.getMaskingChildNodesBySet(survey, optionSetProperty);

    if (maskedNodes.length) {
      const parentOptionSet = survey.getOptionSetByRef(this[optionSetProperty]);
      maskedNodes.forEach((childNode) => {
        const childOptionSet = survey.getOptionSetByRef(childNode[optionSetProperty]);
        parentOptionSet.options.forEach((parentOption, indexOfParent) => {
          const childOptionRef = childNode.getMaskedOptionRefByParentRef(parentOption.ref);
          const indexOfChild = childOptionSet.getOptionIndexByRef(childOptionRef);
          if (indexOfChild !== indexOfParent) {
            childOptionSet.moveOption(survey, indexOfChild, indexOfParent);
          }
        });

        // update question quotas, if applicable
        survey.updateQuestionQuotaGroupQuotaPositions(childNode);

        // recursively check for children with masking
        childNode.updateMaskedChildOptionPositions(survey, optionSetProperty);
      });
    }
  }

  addMaskedOption(parentNode: QuestionType1d | QuestionType2d, parentOption: Option, childOptionSet: OptionSet, survey: Survey, index = null): Option {
    // just ensure it doesn't already exist
    const isOptionInChildSet = this.getMaskedOptionRefByParentRef(parentOption.ref);
    if (isOptionInChildSet) {
      return;
    }

    const newChildOption = new Option(
      childOptionSet.getMaxOptionCode(), //TODO: consider revising this when option codes are visible (usually codes should match parent, but would require updating any other codes for options already existing which when codes are exposed, should be an intentional action by user - i.e. code 99 for none is common)
      uuid(),
      //TODO: note issue with using translations here per: https://maruproduct.atlassian.net/browse/BUILDER-299
      parentOption.specify ? i18n.global.t('question.questionSpecifyPipe', {questionCode: parentNode.code}) : parentOption.label ?? null,
      parentOption.pin ?? null,
      parentOption.exclusive ?? null,
      false, // if the previous option was a specify, the response will be piped instead
      parentOption.i18n ?? null,
      parentOption.asset ?? null,
    )

    if (index === null) {
      // use index from parent - any additional options will always be after parent options
      const parentOptionSet = parentNode.getSet1(survey);
      index = parentOptionSet.getOptionIndexByRef(parentOption.ref);
    }

    // should use its own option config, though probably want to keep hide from the parent question - TBC when we properly support that
    const newChildOptionConfig = cloneDeep(QuestionType1d.DEFAULT_OPTION_CONFIGURATION);
    newChildOptionConfig.parentRef = parentOption.ref;

    childOptionSet.addOption(newChildOption, index, this, newChildOptionConfig, survey);

    return newChildOption;
  }

  deleteMaskedOptions(optionSetProp: OptionSetProperty = 'set1', survey: Survey, deleteMaskingConfiguration = true) {
    const maskedOptions = this.getMaskedOptionsBySet(optionSetProp, survey);
    if (maskedOptions.length) {

      // delete from any children
      maskedOptions.forEach(option => {
        this.updateMaskedChildOption(option, survey, true, false, optionSetProp);
      })

      const optionSet = survey.getOptionSetByRef(this[optionSetProp]);
      optionSet.deleteOptions(survey, this, maskedOptions, optionSetProp);
    }

    if (deleteMaskingConfiguration) {
      if (optionSetProp in this.optionMasking) {
        delete this.optionMasking[optionSetProp];
      }
    }
  }

  deleteMaskingFromChildren(survey: Survey, set: OptionSetProperty = 'set1') {
    const maskingChildNodes = survey.getAllNodesMaskedByNode(this);
    maskingChildNodes.forEach(childNode => {
      survey.resetSurveyErrors(null, childNode.ref, [SurveyErrorProperty.OptionMaskingSet1, SurveyErrorProperty.OptionMaskingSet2]);
      childNode.setQuestionOptionMasking(survey, null, set);
    })
  }

  getMaskingBySet(set: OptionSetProperty): QuestionOptionMaskingProps[] | null {
    return this.optionMasking[set] ?? null;
  }

  getMaskedOptionsBySet(set: OptionSetProperty, survey: Survey): Option[] {
    if (!this.getMaskingBySet(set)) {
      return [];
    }

    const setOptions = survey.getOptionSetByRef(this[set])?.options ?? [];
    return setOptions.filter(option => this.optionConfigurations[option.ref]?.parentRef);
  }

  getMaskedOptionRefByParentRef(parentOptionRef: string): string | null {
    for (const key in this.optionConfigurations) {
      if (this.optionConfigurations[key].parentRef === parentOptionRef) {
        return key;
      }
    }
    return null;
  }

  getParentOfMaskedOption(maskedOption: Option, survey: Survey, optionSetProperty: OptionSetProperty = 'set1', getOriginalParent: boolean = true): Option | null {
    const parentQuestion = this.getMaskingParentNodeBySet(survey, optionSetProperty);
    const parentOptionRef = this.optionConfigurations[maskedOption.ref]?.parentRef;
    if (!parentQuestion || !parentOptionRef || !this.getOptionByRef(maskedOption.ref, survey)) {
      return null;
    }

    const parentOption = parentQuestion.getOptionByRef(parentOptionRef, survey);

    if (!getOriginalParent) {
      return parentOption;
    }

    // get the original parent of the masked option, if any (i.e. if parent question has masking)
    const parentOfParentOption = parentOption ? parentQuestion.getParentOfMaskedOption(parentOption, survey, optionSetProperty, true) : null;
    return parentOfParentOption || parentOption;
  }

  getMaskingParentNodeBySet(survey: Survey, optionSetProperty: OptionSetProperty): QuestionType1d | null {
    const nodeRef = this.optionMasking[optionSetProperty]?.[0]?.parentNode ?? null;
    return nodeRef ? survey.getNodeByRef(nodeRef) as QuestionType1d : null;
  }

  getMaskingChildNodesBySet(survey: Survey, optionSetProperty: OptionSetProperty): QuestionType1d[] {
    const nodes = survey.getAllNodes(QUESTION_TYPES_SUPPORTED_MASKING) as QuestionType1d[];
    return nodes.filter(node => (node as QuestionType1d).optionMasking[optionSetProperty]?.[0]?.parentNode === this.ref);
  }

  isOptionMasked(option: Option, set: OptionSetProperty): boolean {
    return !!(this.getMaskingBySet(set) && this.optionConfigurations[option.ref]?.parentRef);
  }

  deleteQuestionOptionConfiguration(optionRef: string) {
    if (Object.hasOwn(this.optionConfigurations, optionRef)) {
      delete this.optionConfigurations[optionRef];
    }
  }

  getRefs(): Array<Ref> {
    const refs = super.getRefs()

    refs.push(this.set1)
    if (this.quotaGroupRef) {
      refs.push(this.quotaGroupRef)
    }
    if (this.optionConfigurations) {
      refs.push(...Object.keys(this.optionConfigurations))
      refs.push(...Object.values(this.optionConfigurations).map((props: QuestionOptionConfigurationsProps) => props.parentRef).filter(ref => ref))
    }

    return refs
  }
}

export interface QuestionOptionConfigurationsProps {
  rule: string;
  hide: boolean;
  parentRef?: Ref; // the ref of the option masking from, if masking enabled
}

export interface QuestionOptionConfigurations {
  // i.e. option ref: {rule: rule ref, hide: false}
  [ref: Ref]: QuestionOptionConfigurationsProps;
}

export interface OptionSetParams {
  set1?: Ref | null,
  set2?: Ref | null,
  optionConfigurations?: QuestionOptionConfigurations,
  optionMasking?: QuestionOptionMasking,
  addSet1PlaceholderOption?: boolean,
  addSet2PlaceholderOption?: boolean,
  quotaGroupRef?: Ref,
}

export interface QuestionOptionMasking {
  set1?: QuestionOptionMaskingProps[],
  set2?: QuestionOptionMaskingProps[],
}

export interface QuestionOptionMaskingProps {
  type: MaskingType,
  parentNode: string, // question ref masking from
  parentSet: OptionSetProperty // set masking from
}

export type OptionSetProperty = 'set1' | 'set2';

export interface QuestionType1dOrdinalities extends NodeOrdinalities, Partial<QuestionType1dOrdinalitiesLayoutAnswerColumns> {}

export interface QuestionType1dOrdinalitiesLayoutAnswerColumns {
  layoutAnswerColumns: LayoutAnswerColumns
}
